import { Box } from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { departmentsAtom, factoriesAtom, selectedFactoryAtom, uidAtom } from "../../Components/Store/atoms";
import Get from "../../Components/Store/hooks/Get";
import Listen from "../../Components/Store/hooks/Listen";
import fetch from "./fetch";
import GridLayout from "./GridLayout";
import Header from "./Header";

interface Machine {
    id: string;
    hasTarget: boolean;
    name: string;
    target: {
        name: string,
        due?: string,
        target: number,
        started: number;
    },
    status: {
        status: "ON" | "OFF" | "IDLE" | "NA";
        since: number;
    };
    progress: {
        total: number;
        current: number;
        track: number;
        shouldbe: number;
    };
    mold: {
        name: string;
        since: number;
        cycleTime: number;
    };
    hours: {
        production: number;
        downtime: number;
        ontime: number;
        time: string;
        date: string;
    }[];
    OEE: number;
    timing: {
        ontime: number;
        downtime: number;
        projectedEndTime: number; //unix
    };
    operator: {
        name: string;
        shift: string;
    };
    updated: number;
};

const Targets = () => {
    const [department, setDepartment] = useState<string>("ALL");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [rawMachines, setRawMachines] = useState<Machine[] | "NOT_FOUND">([]);
    const [showOnlyWithTargets, setShowOnlyWithTargets] = useState(localStorage.getItem("showOnlyWithTargets") === "true");
    const [refresh, setRefresh] = useState<boolean>(false);
    const factories = Get(factoriesAtom);
    const selectedFactory = Listen(selectedFactoryAtom);
    const uid = Get(uidAtom);
    const departments = Get(departmentsAtom);
    const search = setSearchQuery;

    const { machines, totalMachines, viewingMachines }: {
        machines: Machine[] | "NOT_FOUND",
        totalMachines: number,
        viewingMachines: number
    } = useMemo(() => {
        if (rawMachines === "NOT_FOUND" || !departments) return {
            machines: "NOT_FOUND",
            totalMachines: 0,
            viewingMachines: 0
        };
        const machines: Machine[] = [];
        rawMachines.forEach(machine => {
            if (showOnlyWithTargets) {
                if (machine.hasTarget) machines.push(machine);
            } else machines.push(machine);
        })
        const toReturn = (() => {
            if (searchQuery) {
                return machines.filter(machine => machine.name.toLowerCase().includes(searchQuery.toLowerCase()));
            }
            if (department === "ALL") return machines;
            const machineIDsInDepartment = department === "ALL" ? Object.keys(rawMachines) : Object.values(departments[department].machines || {});
            return machines.filter(machine => machineIDsInDepartment.includes(machine.id));
        })();
        if (toReturn.length === 0 && rawMachines.length > 0) return {
            machines: "NOT_FOUND",
            totalMachines: rawMachines.length,
            viewingMachines: toReturn.length
        };
        return {
            machines: toReturn,
            totalMachines: rawMachines.length,
            viewingMachines: toReturn.length
        };
    }, [rawMachines, department, departments, searchQuery, showOnlyWithTargets]);

    useEffect(() => {
        if (!uid || !factories) return;
        try {
            fetch(factories, selectedFactory).then(setRawMachines);
        } catch (err) {
            console.error(err);
            setRawMachines("NOT_FOUND");
        }
    }, [refresh, uid, factories, selectedFactory]);

    useEffect(() => {
        const interval = setInterval(() => setRefresh(prev => !prev), 30000);
        return () => clearInterval(interval);
    }, []);

    return <Box>
        <Helmet>
            <title>Production Targets | industrialpmr</title>
        </Helmet>
        <Header
            search={search}
            view={"grid"}
            setView={() => { }}
            department={department}
            setDepartment={setDepartment}
            departments={departments || {}}
            total={totalMachines}
            viewing={viewingMachines}
            showOnlyWithTargets={showOnlyWithTargets}
            setShowOnlyWithTargets={setShowOnlyWithTargets}
            hideLayoutSelector
            viewingName={"Machine"} />
        <GridLayout machines={machines === "NOT_FOUND" ? [] : machines} notFound={machines === "NOT_FOUND"} />
    </Box>
}

export type { Machine };
export default Targets