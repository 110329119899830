import { FirebaseOptions } from "firebase/app";

export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyB5aftaKekSPImudIC9qRjge9HWjex3C-8",
  authDomain: "auth.industrialpmr.com",
  databaseURL: "https://industrialpmr-default-rtdb.firebaseio.com",
  projectId: "industrialpmr",
  storageBucket: "industrialpmr.appspot.com",
  messagingSenderId: "817474172678",
  appId: "1:817474172678:web:f54f488cec3af0d3e55087",
  measurementId: "G-YG3DGJLZ9F",
};
